@import '../../style/colors.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  &:not(.visible) {
    display: none;
  }
}

.modal {
  background: white;
  margin: 10px;
  width: 100%;
  max-width: 600px;
  min-height: 300px;
  padding: 20px;
  color: $font-dark-color;
}

.modalHeader {
  font-size: 1.4rem;
  border-bottom: 2px solid $accent-color;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.errorMessage {
  color: red;
  font-size: 0.8rem;
}

.formField {
  margin-bottom: 15px;

  .label,
  input[type='text'],
  input[type='password'] {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    box-sizing: border-box;
  }

  .label {
    display: flex;
    justify-content: space-between;

    .errorMessage {
      color: red;
      font-size: 0.8rem;
    }
  }

  input[type='text'],
  input[type='password'] {
    font-size: 1rem;
    height: 26px;
    padding: 0 5px;
  }
}

.submitButtonWrapper {
  margin-bottom: 15px;

  input[type='submit'] {
    background: $dark-accent-color;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
}

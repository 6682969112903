@import '../style/colors.scss';

.pageHeader {
  border-bottom: 4px solid $accent-color;
  text-align: center;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.4);

  h1 {
    margin: 0;
  }
}

.mainContainer {
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 70px 0 40px 0;

  h2 {
    font-size: 1.2rem;
    margin-top: 0px;
  }

  h2:not(:first-child) {
    margin-top: 65px;
  }
}

.snippetsContainer {
  width: 725px;
  min-width: calc(min(100%, 725px));
}

.modalsContainer {
  margin-left: 55px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .modalsInnerWrapper {
    flex: 1;
  }
}

.modalsContainerCompact {
  display: none;
  margin-bottom: 20px;
  width: 100%;
  max-width: 750px;

  .modalsInnerWrapper {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .pageHeader {
    padding: 15px 0;
  }

  .mainContainer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .mainContainer {
    max-width: calc(100vw - 20px);
    padding: 20px 0;
    margin: 0 10px;
    overflow: hidden;

    h2:not(:first-child) {
      margin-top: 35px;
    }
  }

  .snippetsContainer {
    max-width: 100%;
  }

  .modalsContainer {
    display: none;
  }

  .modalsContainerCompact {
    display: block;
  }
}

@import '../style/colors.scss';

.pageContainer {
  width: calc(100% - 30px);
  min-height: 100%;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signUpSuccessMessage {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fadeIn;
  animation-duration: 1s;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 15px;
    display: block;
    color: #449944;
    font-size: 80px;
  }
}

.em {
  color: $dark-accent-color;
  font-weight: bold;
}

.infoBox {
  padding: 20px;
  font-size: 1.2rem;
}

.errorMessage {
  color: red;
  font-size: 0.8rem;
}

.successMessage {
  color: green;
  font-size: 0.8rem;
}

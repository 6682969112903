@import '../../style/colors.scss';

.startNowButton {
  background: $dark-accent-color;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  font-size: 1.2rem;
  padding: 15px 25px;
  width: 100%;
  display: block;

  &:hover {
    background: lighten($dark-accent-color, 8);
  }
}

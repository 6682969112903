@import './style/colors.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $prime-background-color;
  color: $font-light-color;
  height: 100vh;
}

#root {
  height: 100vh;
}

textarea,
input {
  outline-color: $accent-color;
}

a {
  color: $dark-accent-color;
}

@import '../../style/colors.scss';

.modal {
  padding: 20px;

  form {
    display: flex;
    flex-direction: column;
  }
}

.switchPageMessage {
  display: inline;
  font-size: 0.8rem;
  margin-left: 8px;
}

.submitButtonWrapper {
  margin-bottom: 15px;

  input[type='submit'] {
    background: $dark-accent-color;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background: lighten($dark-accent-color, 8);
    }
  }
}
